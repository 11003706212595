<template>
  <Modal
    ref="modal"
    content-class="unit-form-modal__dialog"
    :z-index="9005"
    modal-name="form"
    :active-modal="activeModal"
    part="unit-form-modal"
  >
    <VCard class="unit-form-modal">
      <VCardTitle>
        <h1 ref="title" class="unit-form-modal__title">
          {{ interpolate(availabilityForm.modalTitle, unit) }}
        </h1>
      </VCardTitle>

      <VCardSubtitle>
        <h2 v-if="unit" class="unit-form-modal__sub-title">
          {{ interpolate(availabilityForm.modalSubtitle, unit) }}
        </h2>
      </VCardSubtitle>

      <VCardText>
        <FormBuilder
          v-if="form && Object.keys(form).length > 0"
          ref="form"
          :form="form"
          :property="property"
          :unit="unit"
          :lang="lang"
          @success="success"
        />
      </VCardText>
    </VCard>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import FormBuilder from './FormBuilder.vue';
import { Unit } from '@/models/Unit';
import { Form } from '@/models/Form';

import {
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
} from 'vuetify/lib/components';
import Modal from '@/components/Modal.vue';
import { FormList } from '@/types/form';
import { AvailabilityForm, AvailabilityTypes } from '@/types/fields';
import { interpolate } from '../lib/interpolation';
import { Property } from '@/models/Property';

export default defineComponent({
  components: {
    Modal,
    FormBuilder,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardText,
  },
  props: {
    forms: {
      type: Object as PropType<FormList>,
      required: true,
    },
    availabilityForms: {
      type: Object as PropType<Record<AvailabilityTypes, AvailabilityForm>>,
      required: true,
    },
    property: {
      type: Property,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      availabilityForm: {} as AvailabilityForm,
      form: {} as Form,
    };
  },
  watch: {
    unit: {
      handler(): void {
        const unit = this.unit;

        if (!unit) {
          return;
        }

        const availabilityForm = this.availabilityForms[unit.available];

        if (availabilityForm && availabilityForm.key) {
          this.availabilityForm = availabilityForm;

          this.form = this.forms[availabilityForm.key];
        } else {
          console.warn(
            'No form matches the following availability:',
            unit.available,
            typeof unit.available,
            this.availabilityForms
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    success() {
      (this.$refs.title! as Element).scrollIntoView({ behavior: 'smooth' })
    },
    interpolate,
    showModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }

      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    },
    hideModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
  },
});
</script>

<style lang="scss">
.unit-form-modal {
  padding: 40px;
  min-width: 40vw;
  max-width: 60vw;

  @media screen and (max-width: 1500px) {
    width: 50vw;
    max-width: 60vw;
  }

  @media screen and (max-width: 768px) {
    width: 80vw;
    max-width: 100vw;
  }

  &__title {
    margin-bottom: 15px;
  }
}
</style>
