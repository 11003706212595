<template>
  <Modal
    ref="modal"
    class="unit-floorplan"
    :z-index="9005"
    modal-name="floorplan"
    :active-modal="activeModal"
    part="unit-floorplan-modal"
  >
    <VCarousel
      class="unit-floorplan__modal"
      :hide-delimiter-background="true"
      :show-arrows="floorplansNoPdf.length > 1"
      hide-delimiters
      show-arrows-on-hover
      height="fit-content"
      :value="activeFloorplan"
      part="unit-floorplan-modal__carousel"
    >
      <template v-for="floorplan in floorplansNoPdf">
        <VCarouselItem
          v-if="floorplan.image"
          :key="floorplan.id"
          :src="getPath(floorplan.image)"
          class="unit-floorplan-modal__item"
          contain
        />
      </template>
    </VCarousel>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Unit } from '@/models/Unit';
import { AssetHandler } from '@/lib/assetHandler';
import { VCarousel, VCarouselItem } from '@vuetify/lib/components';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
    VCarousel,
    VCarouselItem,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    activeFloorplan: {
      type: Number,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    floorplansNoPdf() {
      if (this.unit.floorplans) {
        return this.unit.floorplans.filter(
          floorplan => floorplan.image !== '' && floorplan.image !== null
        );
      }
      return [];
    },
  },
  methods: {
    showModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    getPath(image: string) {
      return AssetHandler.getImagePath(`floorplans/${image}`);
    },
  },
});
</script>

<style lang="scss">
.unit-floorplan {
  --close-btn-color-default: var(--black);

  .v-dialog {
    box-shadow: none;
    max-width: 80vw;
  }

  //&__modal {
  //  padding: 20px;
  //}

  .v-image {
    max-height: 90vh;
  }
}
</style>
