<template>
  <div class="unit-listing" part="unit-listing">
    <div class="unit-listing__content" part="unit-listing-content">
      <template v-if="units.length > 0">
        <Transition
          v-for="unit of units"
          :key="unit.id"
          name="slide"
        >
          <UnitCard
            class="transition-fast-in-fast-out v-card--reveal unit-listing__card"
            :class="{
              'unit-card--selected': hoveredUnit && unit.id === hoveredUnit.id,
            }"
            :unit="unit"
            :data-unit-id="unit.id"
            :card-fields="cardFields"
            :floorplan-navigator="floorplanNavigator"
            @click.native="$emit('unit-click', unit)"
            @mouseover.native="hover(unit)"
            @focus.native="hover(unit)"
            @mouseout.native="hover(undefined)"
            @blur.native="hover(undefined)"
          />
        </Transition>
      </template>

      <div v-else class="unit-listing__no-results">
        {{ settings.noResults }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import UnitCard from './UnitCard.vue';
import { Floor } from '@/models/Floor';
import { Unit } from '@/models/Unit';
import {CardFieldByColumGroup} from '@/types/fields';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import {Settings} from "@/types/settings";

export default defineComponent({
  name: 'UnitListing',
  components: {
    UnitCard,
  },
  props: {
    curFloor: {
      type: Floor,
      required: true,
    },
    hoveredUnit: {
      type: Unit,
    },
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    cardFields: {
      type: Object as PropType<CardFieldByColumGroup>,
      required: true,
    },
    settings: {
      type: Object as PropType<Settings>,
      required: true,
    },
    floorplanNavigator: {
      type: Object as PropType<FloorplanNavigator>,
      required: true,
    },
  },
  emits: ['unit-click', 'unit-hover'],
  data() {
    return {
      hoverUnit: undefined as undefined | Unit,
    };
  },
  methods: {
    hover(unit: Unit | undefined) {
      if (this.hoverUnit && unit && unit.id === this.hoverUnit?.id) {
        return;
      }

      this.hoverUnit = unit;
      this.$emit('unit-hover', unit);
    },
  },
});
</script>

<style lang="scss">
.unit-listing {
  grid-area: unit-listing;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;

    &-track {
      background: var(--offwhite, var(--offwhite-default));
    }

    &-thumb {
      background: var(--action, var(--action-default));
    }
  }

  &__card:last-child {
    border-bottom: 0 !important;
  }

  &__no-results {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 1.375rem;
    color: var(--black, var(--black-default));
    text-align: center;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}
.slide-enter-to {
  position: absolute;
  right: 0;
}
.slide-enter-from {
  position: absolute;
  right: -100%;
}
.slide-leave-to {
  position: absolute;
  left: -100%;
}
.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
