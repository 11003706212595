<template>
  <VApp
    :class="{
      'v-application--modal': isModal,
      'floorplan-navigator__app--default-branding': insertDefaultBranding,
      'floorplan-navigator__app--default-structure': !removeDefaultStructure,
      'floorplan-navigator__app--default-height': !removeDefaultHeights,
      [`floorplan-navigator__app-listing--${listingLayout}`]:
        !removeDefaultStructure || listingLayout === 'none',
      [`floorplan-navigator__app--${appType ? appType : 'default'}`]:
        !removeDefaultStructure,
      [`floorplan-navigator__app-unit-modal--${unitModalPresetSelection}`]:
        !removeDefaultStructure || unitModalPreset === 'none',
    }"
    :style="cssVariables"
  >
    <div
      :class="{
        container: includeContainer,
      }"
      part="container"
    >
      <div
        class="floorplan-navigator--not-authorized"
        v-if="!authorized && isListing"
      >
        <h1>{{ $t('not_authorized') }}</h1>
        <p>{{ $t('reach_out_to_your_provider') }}</p>
      </div>

      <template v-else-if="!floorplanLoaded && isListing">
        <LoadingCircle
          v-if="!disableDefaultAnimation"
          :text="$t('loading_status')"
        />
      </template>

      <template v-else-if="floorplanLoaded && isListing && floors.length === 0">
        <NoAvailability
          :text="$t('no_suite_availability')"
        />
      </template>

      <div
        v-else-if="floorplanLoaded"
        class="floorplan-navigator"
        :part="`floorplan-navigator-wrap floorplan-navigator-wrap--${listingLayout}`"
        :class="`floorplan-navigator--${appType ? appType : 'default'}`"
        ref="wrap"
      >
        <template v-if="isListing">
          <PropertyImage
            v-if="!use3D"
            :building-image="floorplanNavigator.buildingImage"
            :cur-floor="curFloor"
            :floors="floors"
            @floor-click="setFloor"
            @set-ratio="setRatio"
          />

          <Property3D
            v-else
            :cur-floor="curFloor"
            :floors="floors"
            :floorplan-navigator="floorplanNavigator"
            @floor-click="setFloor"
            :lang="lang"
          />

          <FloorSelect
            :floorplan-navigator="floorplanNavigator"
            :cur-floor="curFloor"
            :floors="floors"
            @change-floor="changeFloor"
            :units="filteredUnits"
          />

          <UnitListing
            :units="filteredUnits"
            :hovered-unit="hoveredUnit"
            :cur-floor="curFloor"
            :card-fields="floorplanNavigator.cardFields"
            :settings="floorplanNavigator.settings"
            :floorplan-navigator="floorplanNavigator"
            @unit-click="unitListingSelected"
            @unit-hover="unitListingHover"
          />

          <UnitFilters
            :units="allUnits"
            :filter-fields="floorplanNavigator.filterFields"
            :floors="floors"
            @filters-changed="filtersChanged"
            @set-floor="setFloor"
          />

          <FloorMap
            :hovered-unit="hoveredListingUnit"
            :cur-floor="curFloor"
            :units="filteredUnits"
            @unit-click="unitMapSelected"
            @unit-hover="unitMapHover"
          />
        </template>

        <template v-if="isModal && authorized">
          <UnitModal
            v-if="selectedUnit"
            ref="unitModal"
            :property="floorplanNavigator.building"
            :floorplan-navigator="floorplanNavigator"
            :unit="selectedUnit"
            :modal-tabs="floorplanNavigator.modalTabs"
            :modal-buttons="floorplanNavigator.modalButtons"
            :modal-media-buttons="floorplanNavigator.modalMediaButtons"
            @open-form="openForm"
            @open-gallery="openGallery"
            @open-floorplans="openFloorplans"
            @open-videos="openVideos"
            @open-virtual-tours="openVirtualTours"
            :active-modal="activeModal"
            :availability-forms="floorplanNavigator.availabilityForms"
          />

          <UnitGalleryModal
            v-if="selectedUnit"
            ref="gallery"
            :unit="selectedUnit"
            :active-modal="activeModal"
          />

          <UnitMediaModal
            v-if="selectedUnit"
            ref="media"
            :unit="selectedUnit"
            :media-type="mediaType"
            :active-modal="activeModal"
          />

          <UnitFloorplanModal
            v-if="selectedUnit"
            ref="floorplans"
            :unit="selectedUnit"
            :activeFloorplan="activeFloorplan"
            :active-modal="activeModal"
          />

          <UnitFormModal
            v-if="
              Object.keys(floorplanNavigator.forms).length > 0 && selectedUnit
            "
            ref="form"
            :forms="floorplanNavigator.forms"
            :property="floorplanNavigator.building"
            :unit="selectedUnit"
            :active-modal="activeModal"
            :availability-forms="floorplanNavigator.availabilityForms"
            :lang="lang"
          />
        </template>
      </div>
    </div>
  </VApp>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PropertyImage from './PropertyImage.vue';
import FloorMap from './FloorMap.vue';
import UnitListing from './UnitListing.vue';
import UnitModal from './UnitModal.vue';
import type { Unit } from '@/models/Unit';
import UnitFormModal from './UnitFormModal.vue';
import UnitMediaModal from './UnitMediaModal.vue';
import type { Floor } from '@/models/Floor';
import FloorSelect from './FloorSelect.vue';
import { Availability } from '@/types/unit-type';
import type { UnitFields } from '@/types/unit-type';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { httpClient } from '@/lib/httpClient';
import UnitFilters from './UnitFilters.vue';
import { AssetHandler } from '@/lib/assetHandler';
import UnitGalleryModal from './UnitGalleryModal.vue';
import UnitFloorplanModal from './UnitFloorplanModal.vue';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { VApp } from '@vuetify/lib/components';
import { loadFonts } from '@/plugins/webfontloader';
import LoadingCircle from './LoadingCircle.vue';
import axios from 'axios';
import { isNotProduction } from '@/lib/helpers';
import { eventTracking } from '@/lib/customEvents';
// @ts-ignore
import { v4 as uuidV4 } from 'uuid';
import Property3D from '@/components/Property3D.vue';
import NoAvailability from '@/components/NoAvailability.vue';

export default defineComponent({
  name: 'FloorplanNavigator',
  i18n,
  vuetify,
  components: {
    NoAvailability,
    Property3D,
    UnitGalleryModal,
    UnitFloorplanModal,
    UnitMediaModal,
    UnitFilters,
    FloorSelect,
    UnitFormModal,
    UnitModal,
    UnitListing,
    FloorMap,
    PropertyImage,
    VApp,
    LoadingCircle,
  },
  props: {
    floorplanKey: {
      type: String,
    },
    floorplanEnv: {
      type: String,
      default: 'production',
      validator: (val: string) =>
        ['production', 'staging', 'demo', 'development'].includes(val),
    },
    // Deprecated props
    propertyId: {
      type: Number,
    },
    showUnavailableUnits: {
      type: Boolean,
    },
    // Add the branding to aspen in the platform
    includeDefaultBranding: {
      type: Boolean,
    },
    // TODO: Once Soho italia's issues are addressed, remove this prop (breaking change)
    removeDefaultStructure: {
      type: Boolean,
    },
    includeContainer: {
      type: Boolean,
    },
    // End deprecated props

    // Move to the platform
    enableListingAutoscroll: {
      type: Boolean,
    },
    disableListingAutoscrollAt: {
      type: Number,
      default: 1200,
    },
    // end move to the platform
    removeDefaultHeights: {
      type: Boolean,
    },
    appType: {
      type: String,
      required: true,
      validator: (val: string) => ['modal', 'listing'].includes(val),
    },
    unitModalPreset: {
      type: String,
      default: 'tall',
      validator: (val: string) => ['default', 'tall', 'none'].includes(val),
    },
    disableDefaultAnimation: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      selectedUnit: undefined as Unit | undefined,
      floorplanNavigator: {} as FloorplanNavigator,
      allUnitsById: {} as Record<number, Unit>,
      allUnitsByUnitNumber: {} as Record<string, Unit>,
      curFloorI: 0,
      hoveredUnit: undefined as undefined | Unit,
      hoveredListingUnit: undefined as undefined | Unit,
      selectedFilters: {} as UnitFields,
      activeFloorplan: 0,
      mediaType: 'videos',
      modalFpnEl: undefined as undefined | Element,
      listingFpnEl: undefined as undefined | Element,
      fpnEl: undefined as undefined | Element,
      activeModal: '',
      unitModalPresetSelection: '',
      authorized: true,
      waitForDataI: 0,
      insertDefaultBranding: false,
      observer: {} as IntersectionObserver,
      viewThreshold: 0.25,
      key: '',
    };
  },
  watch: {
    async lang() {
      this.$i18n.locale = this.lang;

      if (this.floorplanKey) {
        await this.getFloorplan();
      } else if (this.propertyId) {
        await this.getFloorplan(`,propertyId:${this.propertyId}`);
      }
    },
  },
  computed: {
    use3D(): boolean {
      return (
        this.floorplanNavigator.instanceSettings &&
        this.floorplanNavigator.instanceSettings.hauzd &&
        !!this.floorplanNavigator.instanceSettings.hauzd.url
      );
    },
    listingLayout(): string {
      return this.floorplanNavigator.listingLayout === 'simplified'
        ? 'default'
        : this.floorplanNavigator.listingLayout;
    },
    cssVariables() {
      let variables = '';

      if (Object.keys(this.floorplanNavigator).length > 0) {
        if (this.floorplanNavigator.settings.buildingOverlayOpacity) {
          variables = `--overlay-opacity: ${this.floorplanNavigator.settings.buildingOverlayOpacity};`;
        } else {
          variables = `--overlay-opacity: 0.5;`;
        }
      }

      return variables;
    },
    floors(): Floor[] {
      if (!this.floorplanNavigator.settings.showUnavailableFloors) {
        let floors: Floor[] = [];

        this.floorplanNavigator.floors.forEach(floor => {
          floor.units.some((unit: Unit) => {
            if (unit.available !== Availability.unavailable) {
              floors.push(floor);

              return true;
            }
          });
        });

        return floors;
      }

      return this.floorplanNavigator.floors;
    },
    curFloor(): Floor {
      return this.floors[this.curFloorI];
    },
    units(): Unit[] {
      return this.curFloor.units.reduce((unitList: Unit[], unit: Unit) => {
        if (
          this.floorplanNavigator.settings.showUnitStatus &&
          this.floorplanNavigator.settings.showUnitStatus.includes(
            unit.available
          )
        ) {
          unitList.push(unit);
        }

        if (
          this.floorplanNavigator.settings.showUnitStatus === undefined &&
          (unit.available !== Availability.unavailable ||
            this.showUnavailableUnits)
        ) {
          unitList.push(unit);
        }

        return unitList;
      }, [] as Unit[]);
    },
    allUnits(): Unit[] {
      const units = [];

      for (const floor of this.floors) {
        for (const unit of floor.units) {
          if (
            this.floorplanNavigator.settings.showUnitStatus &&
            this.floorplanNavigator.settings.showUnitStatus.includes(
              unit.available
            )
          ) {
            units.push(unit);
          }

          if (
            this.floorplanNavigator.settings.showUnitStatus === undefined &&
            (unit.available !== Availability.unavailable ||
              this.showUnavailableUnits)
          ) {
            units.push(unit);
          }
        }
      }

      return units;
    },
    filteredUnits(): Unit[] {
      if (this.isModal) {
        return [];
      }

      return this.units.reduce((unitList: Unit[], unit: Unit) => {
        const filterKeys = Object.keys(this.selectedFilters) as (keyof Unit)[];

        if (filterKeys.length > 0) {
          for (const filterKey of filterKeys) {
            const filter = this.floorplanNavigator.filterFields.find(
              filter => filter.field === filterKey
            );

            if (!filter) {
              continue;
            }

            let unitValue = unit[filterKey];
            let filterValue = this.selectedFilters[filterKey];
            const filterAction = filter.filter;
            if (
              unitValue === null ||
              unitValue === undefined ||
              filterValue === null ||
              filterValue === undefined ||
              (Array.isArray(filterValue) && filterValue.length === 0)
            ) {
              continue;
            }

            if (filterAction === 'min' || filterAction === 'max') {
              unitValue = Number.parseInt(unitValue as any, 10);
              filterValue = Number.parseInt(filterValue as any, 10);
            }

            if (unitValue < filterValue && filterAction === 'min') {
              return unitList;
            } else if (unitValue > filterValue && filterAction === 'max') {
              return unitList;
            } else if (unitValue !== filterValue && filterAction === 'exact') {
              return unitList;
            }
          }
        }

        unitList.push(unit);

        return unitList;
      }, [] as Unit[]);
    },
    isModal(): boolean {
      return this.appType === 'modal';
    },
    isListing(): boolean {
      return this.appType === 'listing';
    },
    floorplanLoaded(): boolean {
      return Object.keys(this.floorplanNavigator).length > 0;
    },
  },
  created() {
    this.unitModalPresetSelection = this.unitModalPreset;
    this.observer = new IntersectionObserver(this.loadIntersection, {
      threshold: [this.viewThreshold],
    });
  },
  async mounted() {
    if (!window.fpnSessionId) {
      window.fpnSessionId = uuidV4();
      window.fpnUnitClicks = {};
      window.fpnClicks = [];
    }

    this.$i18n.locale = this.lang;
    this.key = this.floorplanKey as string;
    let env = this.floorplanEnv;

    if (isNotProduction(false)) {
      const urlParams = new URLSearchParams(window.location.search);
      const keyParam = urlParams.get('key');
      const langParam = urlParams.get('lang');
      const envParam =
        urlParams.get('env') || (keyParam !== null ? 'production' : null);

      if (keyParam !== null) {
        this.key = keyParam;
      }

      if (langParam !== null) {
        this.$i18n.locale = langParam;
      }

      if (envParam !== null) {
        env = envParam;
      }
    }

    if (this.key) {
      httpClient.setFpnConfig({
        fpnKey: this.key,
        env,
        // hostOverride: 'localhost:5015',
      });

      if (env === 'demo' || this.includeDefaultBranding) {
        this.insertDefaultBranding = true;
      }

      await this.getFloorplan();
    } else {
      if (!this.propertyId) {
        console.error(
          'Missing data, please ensure property-id or floorplan-key is defined'
        );
        return;
      }

      httpClient.setFpnConfig({
        fpnKey: 'fpn_legacy',
        env,
      });

      await this.getFloorplan(`,propertyId:${this.propertyId}`);
    }

    if (!this.authorized) {
      return;
    }

    AssetHandler.setStoragePath('aws', this.floorplanNavigator.client);

    httpClient.recaptchaPublicKey = this.floorplanNavigator.recaptchaSiteKey;

    if (this.isListing) {
      this.changeFloor(0);
    }

    if (this.isModal) {
      this.allUnitsById = {};
      this.allUnitsByUnitNumber = {};

      for (const floor of this.floors) {
        for (const unit of floor.units) {
          this.allUnitsById[unit.id] = unit;
          this.allUnitsByUnitNumber[unit.number] = unit;
        }
      }
    }

    loadFonts(this.$root.$el);
    this.addStyles();

    let fpns = document.body.querySelectorAll(
      `floorplan-navigator[floorplan-key="${this.key}"]`
    );

    if (fpns.length === 0) {
      fpns = document.body.querySelectorAll('floorplan-navigator');
    }

    for (const fpn of fpns) {
      const appType = fpn.getAttribute('app-type');

      if (appType === 'modal' && this.appType !== 'modal') {
        this.modalFpnEl = fpn;
      }

      if (appType === 'listing' && this.appType !== 'listing') {
        this.listingFpnEl = fpn;
      }

      if (
        appType === this.appType ||
        (appType === null &&
          (this.appType === undefined || this.appType === ''))
      ) {
        this.fpnEl = fpn;
      }

      if (appType === 'modal' && this.appType === 'modal') {
        fpn.addEventListener('open-modal', e => {
          const data = (e as any).detail;
          const unit = this.getUnitFromCustomEvent(data);

          if (unit) {
            this.selectedUnit = unit;
            this.openModal(unit);
          }
        });

        fpn.addEventListener('open-floorplans', e => {
          const data = (e as any).detail;
          const unit = this.getUnitFromCustomEvent(data);

          if (unit) {
            this.selectedUnit = unit;
            this.openFloorplans(data.activeFloorplan ?? 0);
          }
        });

        fpn.addEventListener('open-videos', e => {
          const data = (e as any).detail;
          const unit = this.getUnitFromCustomEvent(data);

          if (unit) {
            this.selectedUnit = unit;
            this.openVideos();
          }
        });

        fpn.addEventListener('open-gallery', e => {
          const data = (e as any).detail;
          const unit = this.getUnitFromCustomEvent(data);

          if (unit) {
            this.selectedUnit = unit;
            this.openGallery();
          }
        });
      }
    }

    this.emitFpnEvent('initialized');
  },
  methods: {
    getUnitFromCustomEvent(data: Record<string, any>) {
      if (data.unitId && this.allUnitsById && this.allUnitsById[data.unitId]) {
        return this.allUnitsById[data.unitId];
      }

      if (data.unitNumber && this.allUnitsByUnitNumber && this.allUnitsByUnitNumber[data.unitNumber]) {
        return this.allUnitsByUnitNumber[data.unitNumber];
      }

      return null;
    },
    loadIntersection(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= this.viewThreshold) {
          eventTracking('load');
          this.observer.disconnect();
        }
      });
    },
    setRatio(ratio: number) {
      (this.$refs.wrap as Element).setAttribute(
        'style',
        `--width-ratio-default: ${ratio};`
      );
    },
    async getFloorplan(queryParams: string = '') {
      if (!window.fpnData) {
        window.fpnData = {};
      }

      if (window.fpnData[this.key] === undefined) {
        window.fpnData[this.key] = null;

        try {
          const floorplanNavigatorResponse = await httpClient.get({
            url: `floorplan-navigator?where=lang:${this.$i18n.locale}${queryParams}`,
          });

          if (floorplanNavigatorResponse.status === 401) {
            this.authorized = false;
          } else {
            window.fpnData[this.key] = floorplanNavigatorResponse.data;
            this.floorplanNavigator = new FloorplanNavigator(
              floorplanNavigatorResponse.data
            );
            // Wait some time for images to load.
            window.setTimeout(() => {
              this.observer.observe(this.$el);
            }, 500);
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            this.authorized = e.status != '401';
          }
        }
      } else {
        await new Promise(resolve => this.recursiveWaitForFpnData(resolve));
      }
    },
    async recursiveWaitForFpnData(resolve: (value?: unknown) => void) {
      // Gives the request 30 seconds to load before calling it quits.
      do {
        if (window.fpnData && window.fpnData[this.key] !== null) {
          this.floorplanNavigator = new FloorplanNavigator(
            window.fpnData[this.key]!
          );
        } else {
          await new Promise(timeOutResolve => setTimeout(timeOutResolve, 500));
        }

        this.waitForDataI++;
      } while (
        Object.keys(this.floorplanNavigator).length === 0 &&
        this.waitForDataI < 60
      );

      if (Object.keys(this.floorplanNavigator).length === 0) {
        this.authorized = false;
      }

      resolve();
    },
    addStyles() {
      let styles = this.$el.querySelector('#fpn-styles');

      if (!styles) {
        styles = document.createElement('style');
        styles.setAttribute('id', 'fpn-styles');
        this.$el.append(styles);
      }

      if (styles) {
        styles.innerHTML = this.floorplanNavigator.legacyStyles;
      }

      if (this.floorplanKey && !document.querySelector(`#fpn-head-styles[data-fpn="${this.floorplanKey}"]`)) {
        const headStyles = document.createElement('style');
        headStyles.setAttribute('id', 'fpn-head-styles');
        headStyles.setAttribute('data-fpn', this.floorplanKey);
        headStyles.innerHTML = `${this.floorplanNavigator.styles} `;

        if (this.floorplanNavigator.cssStyles) {
          headStyles.innerHTML += this.floorplanNavigator.cssStyles;
        }
        document.head.append(headStyles);
      }
    },
    unitListingSelected(unit: Unit) {
      this.openModal(unit);
    },
    unitMapSelected(unit: Unit) {
      this.openModal(unit);
    },
    openFloorplans(activeFloorplan: number) {
      this.activeFloorplan = activeFloorplan;
      this.activeModal = 'floorplan';

      if (this.isModal) {
        (this.$refs.floorplans as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-floorplans', { activeFloorplan });
      }
    },
    openVideos() {
      this.mediaType = 'videos';
      this.activeModal = 'media';

      eventTracking('view-videos', this.selectedUnit!.id);

      if (this.isModal) {
        (this.$refs.media as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-videos');
      }
    },
    openVirtualTours() {
      this.mediaType = 'virtual-tours';
      this.activeModal = 'media';

      eventTracking('view-virtual-tours', this.selectedUnit!.id);

      if (this.isModal) {
        (this.$refs.media as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-virtual-tours');
      }
    },
    sendModalEvent(
      eventName: string,
      extraOptions: Record<string, unknown> = {}
    ) {
      if (this.selectedUnit) {
        const event = new CustomEvent(eventName, {
          detail: { unitId: this.selectedUnit.id, ...extraOptions },
        });

        if (this.appType !== 'modal') {
          this.modalFpnEl?.dispatchEvent(event);
        } else {
          this.fpnEl?.dispatchEvent(event);
        }
      }
    },
    emitFpnEvent(eventName: string, opts?: Record<string, unknown>) {
      const event = new CustomEvent(eventName, opts);
      this.fpnEl?.dispatchEvent(event);
    },
    async openModal(unit: Unit) {
      this.selectedUnit = unit;
      this.activeModal = 'unit';

      await this.$nextTick();

      if (this.isModal) {
        if (this.$refs && this.$refs.unitModal) {
          (this.$refs.unitModal as any).showModal();
        }

        eventTracking('view-unit', this.selectedUnit!.id);
      } else if (this.isListing) {
        this.sendModalEvent('open-modal');
      }
    },
    openForm() {
      eventTracking('open-form', this.selectedUnit!.id);
      const availabilityForm =
        this.floorplanNavigator.availabilityForms[this.selectedUnit!.available];

      if (
        availabilityForm.overriddenByApplicationUrl &&
        this.selectedUnit?.applicationUrl
      ) {
        this.sendModalEvent('open-application-url');
        window
          .open(
            this.selectedUnit.applicationUrl.replaceAll('&amp;', '&'),
            '_blank'
          )
          ?.focus();
      } else {
        if (availabilityForm.type === 'form') {
          if (this.isModal) {
            this.activeModal = 'form';
            (this.$refs.form as any).showModal();
          } else if (this.isListing) {
            this.sendModalEvent('open-form');
          }
        } else {
          window
            .open(availabilityForm.link.replaceAll('&amp;', '&'), '_blank')
            ?.focus();
        }
      }
    },
    openGallery() {
      if (this.isModal) {
        eventTracking('view-gallery', this.selectedUnit!.id);

        this.activeModal = 'gallery';

        (this.$refs.gallery as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-gallery');
      }
    },
    setFloor(floor: Floor) {
      this.curFloorI = this.floors.findIndex(
        fFloor => fFloor.number === floor.number
      );

      let styles = this.$el.querySelector('#floor-styles');

      if (!styles) {
        styles = document.createElement('style');
        styles.setAttribute('id', 'floor-styles');
        this.$el.append(styles);
      }

      if (styles && floor.css) {
        styles.innerHTML = `${floor.css}`;
      }
    },
    filtersChanged(values: UnitFields) {
      this.selectedFilters = values;
    },
    changeFloor(i: number) {
      this.curFloorI += i;

      if (this.curFloorI < 0) {
        this.curFloorI = this.floors.length - 1;
      } else if (this.curFloorI >= this.floors.length) {
        this.curFloorI = 0;
      }

      this.setFloor(this.floors[this.curFloorI]);
    },
    unitMapHover(unit: Unit | undefined) {
      this.$nextTick(() => {
        if (
          unit &&
          this.enableListingAutoscroll &&
          window.outerWidth > this.disableListingAutoscrollAt
        ) {
          const el = this.$el.querySelector(
            `.unit-card[data-unit-id="${unit.id}"]`
          );

          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }
        this.hoveredUnit = unit;
      });
    },
    unitListingHover(unit: Unit | undefined) {
      this.hoveredListingUnit = unit;
    },
  },
});
</script>

<style lang="scss">
@import '@/styles/mdi';
@import '@/styles/vuetify-components';
@import '@/styles/vuetify-overrides';

#app {
  text-align: left !important;
}

.floorplan-navigator {
  &__app {
    &--default-branding {
      // Global variables
      --action-default: #4078b5;
      --accent-default: #23527c;
      --white-default: #ffffff;
      --offwhite-default: #eef3f8;
      --black-default: #000000;
      --body-font-default: 'Roboto', sans-serif;
      --heading-font-default: 'Roboto', sans-serif;
    }

    &--default-structure {
      // FPN - Variables (this component)
      --unit-card-action-color-default: var(
        --offwhite,
        var(--offwhite-default)
      );
      --floorplan-nav-border-default: 2px solid
        var(--black, var(--black-default));
      --modal-padding-default: 80px 40px 40px 40px;
      --modal-content-gap-default: 3rem 0;
      --modal-template-columns-default: 50% 50%;
      --unit-filters-bg-default: var(--black, var(--black-default));
      --unit-filters-font-color-default: var(--white, var(--white-default));
      --unit-filters-padding-default: 1rem 0.6rem;
      --unit-filters-col-grid-default: repeat(auto-fit, minmax(100px, auto));
    }

    &--modal {
      .v-application--wrap > .container {
        padding: unset;
      }
    }

    &-unit-modal {
      &--default {
        --modal-template-areas-default: 'actions actions' 'info media'
          'view-media view-media';
        --modal-floorplan-height-default: 300px;
      }

      &--tall {
        --modal-template-areas-default: 'actions media' 'info media'
          'view-media media';
        --modal-floorplan-height-default: 500px;
      }
    }

    &-listing {
      &--default {
        --template-areas-default: 'property-image floor-select unit-filters'
          'property-image unit-listing floor-map';
        --template-columns-default: auto 2fr minmax(200px, 4fr);
        --template-rows-default: min-content 1fr;

        @media (max-width: 1899px) {
          --template-columns-default: auto 3fr minmax(200px, 4fr);
        }

        @media (max-width: 1199px) {
          --template-areas-default: 'property-image floor-select'
            'property-image unit-listing' 'unit-filters unit-filters'
            'floor-map floor-map';
          --template-columns-default: auto 1fr;
        }

        @media (max-width: 767px) {
          --template-areas-default: 'unit-filters' 'unit-listing' 'floor-select'
            'floor-map';
          --template-columns-default: 1fr;
        }

        &.floorplan-navigator__app--default-height {
          .floorplan-navigator--listing,
          .floorplan-navigator--default {
            height: 720px;

            @media (max-width: 1899px) {
              height: 600px;
            }

            @media (max-width: 1199px) {
              height: unset;

              .unit-listing {
                height: 514px;
              }
            }

            @media (max-width: 767px) {
              height: unset;

              .unit-listing {
                height: 400px;
              }

              .property-image,
              .property-image-3d {
                display: none;
              }
            }
          }

          .property-image,
          .property-image-3d {
            --building-height-default: 600px;

            @media (min-width: 1900px) {
              --building-height-default: 720px;
            }

            @media (min-width: 768px) and (max-width: 1199px) {
              --building-height-default: 600px;
            }

            width: calc(
              var(--building-height, var(--building-height-default)) *
                var(--width-ratio, var(--width-ratio-default))
            );
            height: var(--building-height, var(--building-height-default));

            img {
              width: calc(
                var(--building-height, var(--building-height-default)) *
                  var(--width-ratio, var(--width-ratio-default))
              );
              height: var(--building-height, var(--building-height-default));
            }
          }
        }
      }

      &--stacked {
        --template-areas-default: 'unit-filters unit-filters'
          'property-image floor-map' 'floor-select floor-select'
          'unit-listing unit-listing';
        --template-columns-default: 50% 50%;
        --template-rows-default: min-content 1fr;

        @media (max-width: 991px) {
          --template-areas-default: 'unit-filters' 'property-image' 'floor-map'
            'floor-select' 'unit-listing';
          --template-columns-default: 1fr;
        }

        @media (max-width: 767px) {
          --template-areas-default: 'floor-map' 'unit-filters' 'floor-select'
            'unit-listing';
          --template-columns-default: 1fr;
        }

        .unit-listing {
          overflow-x: auto;

          &__content {
            height: 230px;
            display: flex;
            flex-direction: row;
          }
        }

        .unit-card {
          min-width: 230px;
        }

        &.floorplan-navigator__app--default-height {
          .property-image,
          .property-image-3d {
            --building-height-default: 500px;

            @media (min-width: 1900px) {
              --building-height-default: 620px;
            }

            @media (max-width: 1263px) {
              --building-height-default: 400px;
            }

            @media (max-width: 991px) {
              --building-height-default: 500px;
            }

            @media (max-width: 767px) {
              display: none;
            }

            width: calc(
              var(--building-height, var(--building-height-default)) *
                var(--width-ratio, var(--width-ratio-default))
            );
            height: var(--building-height, var(--building-height-default));

            img {
              width: calc(
                var(--building-height, var(--building-height-default)) *
                  var(--width-ratio, var(--width-ratio-default))
              );
              height: var(--building-height, var(--building-height-default));
            }
          }
        }
      }

      &--stacked-alt {
        --template-areas-default: 'floor-select unit-filters'
          'property-image unit-listing' 'floor-map unit-listing';
        --template-columns-default: 70% 30%;
        --template-rows-default: 100px
          var(--building-height, var(--building-height-default)) 600px;

        @media (max-width: 1300px) {
          --template-areas-default: 'unit-filters' 'property-image' 'floor-map'
            'floor-select' 'unit-listing';
          --template-columns-default: 1fr;
          --template-rows-default: 1fr;
        }

        @media (max-width: 767px) {
          --template-areas-default: 'floor-map' 'unit-filters' 'floor-select'
            'unit-listing';
          --template-columns-default: 1fr;
          --template-rows-default: 1fr;
        }

        .unit-listing {
          overflow-y: auto;

          &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }

        .unit-card {
          min-width: 230px;
        }

        &.floorplan-navigator__app--default-height {
          .property-image,
          .property-image-3d {
            --building-height-default: 300px;

            //@media (min-width: 1900px) {
            //  --building-height-default: 300px;
            //}
            //
            //@media (max-width: 1263px) {
            //  --building-height-default: 300px;
            //}
            //
            //@media (max-width: 991px) {
            //  --building-height-default: 500px;
            //}

            @media (max-width: 767px) {
              display: none;
            }

            width: 100%;
            height: var(--building-height, var(--building-height-default));

            img {
              width: 100%;
              height: var(--building-height, var(--building-height-default));
            }
          }
        }
      }
    }
  }

  &.floorplan-navigator--listing {
    display: grid;
    grid-template-areas: var(--template-areas, var(--template-areas-default));
    grid-template-columns: var(
      --template-columns,
      var(--template-columns-default)
    );
    grid-template-rows: var(--template-rows, var(--template-rows-default));
    text-align: left;
  }

  ::-webkit-scrollbar {
    width: var(--scrollbar-width, 17px);
  }

  ::-webkit-scrollbar-thumb {
    background: var(
      --scrollbar-thumb,
      linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))
        var(--accent, var(--accent-default))
    );
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-track, var(--white, var(--white-default)));
  }

  &--listing,
  &--default {
    border: var(--floorplan-nav-border, var(--floorplan-nav-border-default));
    box-sizing: content-box;
  }

  &--not-authorized {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px;
    background: #d5d5d5;
  }
}
// Added this so the dialogs don't cover the entire page if it's not necessary
.v-dialog.v-dialog--active {
  width: unset;
}

.v-application--wrap {
  min-height: unset !important;
}
</style>
