<template>
  <div class="floor-select" part="floor-select">
    <VIcon
      class="floor-select__previous-floor"
      @click="$emit('change-floor', -1)"
    >
      mdi-chevron-left
    </VIcon>

    <span class="floor-select__floor-number">
      {{ floorSelectTitle }}
    </span>

    <span class="floor-select__units-available">
      {{ floorSelectSubtitle }}
    </span>

    <VIcon class="floor-select__next-floor" @click="$emit('change-floor', 1)">
      mdi-chevron-right
    </VIcon>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { Availability } from '@/types/unit-type';
import { VIcon } from '@vuetify/lib/components';
import { Unit } from '@/models/Unit';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { interpolate } from '@/lib/interpolation';

export default defineComponent({
  name: 'FloorSelect',
  components: {
    VIcon,
  },
  props: {
    floorplanNavigator: {
      type: FloorplanNavigator,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    floors: {
      type: Array as PropType<Floor[]>,
      required: true,
    },
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
  },
  emits: ['change-floor'],
  computed: {
    floorSelectTitle() {
      // If the label contains anything that's not a number
      if (!/^\d+$/.test(this.curFloor.label)) {
        return interpolate(
          this.curFloor.label,
          this.curFloor,
          this.curFloor.number
        );
      }

      return this.$t('floor_number', { number: this.curFloor.number });
    },
    floorSelectSubtitle() {
      if (this.floorplanNavigator.settings.floorSelectSubtitle) {
        return interpolate(
          this.floorplanNavigator.settings.floorSelectSubtitle,
          {
            available: this.availableUnits,
            availableOn: this.availableOnUnits,
            availableNow: this.availableNowUnits,
          },
          this.availableUnits
        );
      }

      return this.$tc('available_units', this.availableUnits, { count: this.availableUnits });
    },
    availableUnits() {
      return this.units.filter(
        unit => unit.available === Availability.available || unit.available === Availability.availableOn
      ).length;
    },
    availableOnUnits() {
      return this.units.filter(
        unit => unit.available === Availability.availableOn
      ).length;
    },
    availableNowUnits() {
      return this.units.filter(
        unit => unit.available === Availability.available
      ).length;
    },
  },
});
</script>

<style lang="scss">
.floor-select {
  // Variables
  --floor-select-bg-default: var(--black, var(--black-default));
  --floor-select-font-color-default: var(--white, var(--white-default));
  --floor-select-padding-default: 0;
  --floor-select-border-width-default: 0;
  --floor-select-border-color-default: var(--black, var(--black-default));
  --floor-select-template-areas-default: 'previous-floor floor-number next-floor'
    'previous-floor units-available next-floor';
  --floor-select-template-rows-default: auto;

  grid-area: floor-select;
  display: grid;
  grid-template-areas: var(--floor-select-template-areas, var(--floor-select-template-areas-default));
  grid-template-rows: var(--floor-select-template-rows, var(--floor-select-template-rows-default));
  position: relative;
  align-items: center;
  justify-content: space-evenly;

  padding: var(--floor-select-padding, var(--floor-select-padding-default));
  background-color: var(--floor-select-bg, var(--floor-select-bg-default));
  color: var(--floor-select-font-color, var(--floor-select-font-color-default));
  border-width: var(--floor-select-border-width, var(--floor-select-border-width-default));
  border-color: var(--floor-select-font-color, var(--floor-select-font-color-default));
  border-style: solid;

  &__previous-floor {
    grid-area: previous-floor;
  }

  &__next-floor {
    grid-area: next-floor;
  }

  &__next-floor,
  &__previous-floor {
    color: rgba(var(--floor-select-font-color, var(--floor-select-font-color-default)), 0.54) !important;

    &:focus::after {
      display: none;
    }
  }

  &__units-available {
    grid-area: units-available;
  }

  &__floor-number {
    font-family: var(--heading-font, var(--heading-font-default));
    grid-area: floor-number;
    text-transform: uppercase;
    font-size: var(--floor-select-font-size, 1.375rem);
  }

  &__floor-number,
  &__units-available {
    text-align: center;
  }

  @media (max-width: 1199px) {
    --floor-select-padding-default: 0.9rem 0.6rem;
  }

  @media (max-width: 767px) {
    --floor-select-bg-default: transparent;
    --floor-select-font-color-default: var(--black, var(--black-default));
    --floor-select-padding-default: 1.75rem 0.8rem;
    --floor-select-border-width-default: 1px 0 1px 0;
  }
}
</style>
