<template>
  <VCard class="unit-card" elevation="5" :part="getParts()">
    <VCardTitle v-if="cardFields.title && cardFields.title.length">
      <template v-for="(cardField, index) of cardFields.title">
        <span
          v-if="interpolateCustom(cardField)"
          :key="`title-${index}`"
          :class="`unit-card__title--${cardField.field}`"
          :part="`unit-field-value--${cardField.field}`"
        >
          {{ interpolateCustom(cardField) }}
        </span>
      </template>
    </VCardTitle>

    <VCardText
      v-if="
        (cardFields.body && cardFields.body.length) ||
        (cardFields.footer && cardFields.footer.length)
      "
    >
      <div class="unit-card__fields">
        <template v-for="(cardField, index) of cardFields.body">
          <span
            v-if="interpolateCustom(cardField)"
            :key="`body-${index}`"
            :class="`unit-card__${cardField.field}`"
            :part="`unit-field-value--${cardField.field}`"
          >
            {{ interpolateCustom(cardField) }}
          </span>
        </template>

        <div
          v-if="cardFields.footer && cardFields.footer.length > 0"
          class="unit-card__footer"
        >
          <template v-for="(cardField, index) of cardFields.footer">
            <span
              v-if="interpolateCustom(cardField)"
              :key="`footer-${index}`"
              :class="`unit-card__${cardField.field} unit-card--footer`"
              :part="`unit-field-value--${cardField.field}`"
            >
              {{ interpolateCustom(cardField) }}
            </span>
          </template>
        </div>
      </div>

      <VIcon class="unit-card__arrow">mdi-chevron-right</VIcon>
    </VCardText>
  </VCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Unit } from '@/models/Unit';
import { Availability } from '@/types/unit-type';
import { VCard, VCardText, VCardTitle, VIcon } from '@vuetify/lib/components';
import { CardField, CardFieldByColumGroup, ModalField } from '@/types/fields';
import { interpolate } from '@/lib/interpolation';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { isCardCustomField, isModalCustomField } from '@/lib/typeGuards';

export default defineComponent({
  name: 'UnitCard',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    cardFields: {
      type: Object as PropType<CardFieldByColumGroup>,
      required: true,
    },
    floorplanNavigator: {
      type: Object as PropType<FloorplanNavigator>,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: 0,
    };
  },
  methods: {
    getParts() {
      let parts = 'unit-listing-card';

      if (
        this.unit.available === Availability.available &&
        this.unit.availabilityDate !== '0000-00-00' &&
        this.unit.availabilityDate !== null
      ) {
        parts += ' unit-listing-card--available-on';
      } else if (this.unit.available === Availability.available) {
        parts += ' unit-listing-card--available';
      } else if (this.unit.available === Availability.waitlist) {
        parts += ' unit-listing-card--waitlist';
      } else {
        parts += ' unit-listing-card--unavailable';
      }

      return parts;
    },
    interpolate,
    interpolateCustom(field: CardField) {
      if (!isCardCustomField(field)) {
        const value =
          field.field === 'rate' && field.numberFormat === 'currency'
            ? Number.parseFloat(
                this.unit[field.field] as string
              ).toLocaleString('en-CA', {
                maximumFractionDigits: 2,
              })
            : this.unit[field.field];

        return interpolate(field.format, this.unit, value);
      }

      const value = this.unit.customFields[field.customField];

      return interpolate(field.format, this.unit, value);
    },
  },
});
</script>

<style lang="scss">
.unit-card {
  border-bottom: 1px solid var(--black, var(--black-default));
  border-radius: 0 !important;

  &:hover {
    cursor: pointer;
    background: var(
      --unit-card-action-color,
      var(--unit-card-action-color-default)
    );
  }

  &:before {
    &:hover {
      background: var(--black, var(--black-default));
    }
  }

  &--selected.v-card {
    background: var(
      --unit-card-action-color,
      var(--unit-card-action-color-default)
    );
  }

  .v-card__title,
  .unit-card__footer {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }

  .v-card__text {
    display: grid;
    grid-template-areas: 'filters arrow';
    grid-auto-flow: row;
    justify-content: space-between;
    align-items: center;
  }

  .unit-card__footer {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__fields {
    grid-area: filters;
    display: flex;
    flex-direction: column;
  }

  &__arrow {
    grid-area: arrow;
  }
}
</style>
