<template>
  <div class="floor-map" part="floor-map">
    <svg
      v-if="useSvg"
      :id="`svg-floor-layout-${curFloor.number}`"
      :class="`svg-floor-layout floor-${curFloor.number}`"
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="curFloor.viewbox"
      part="floor-map-svg"
    >
      <g id="unit_paths">
        <a
          v-for="unit of curFloor.units"
          :key="unit.id"
          href="#"
          class="unit"
          :class="unitClasses(unit)"
          :part="unitParts(unit)"
          @click.prevent="click(unit)"
          @mouseover="hover(unit)"
          @focus="hover(unit)"
          @mouseout="hover(undefined)"
          @blur="hover(undefined)"
          v-html="decodeHtml(unit.unitCoordinates)"
        />
      </g>

      <g v-html="decodeHtml(curFloor.elements)" />
    </svg>

    <img
      v-else
      class="floor-map__image"
      :src="curFloor.image"
      alt="floorplate image"
      part="floor-map-image"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { Unit } from '@/models/Unit';
import { Availability } from '@/types/unit-type';
import { decodeHtml } from '@/lib/helpers';
import { AssetHandler } from '@/lib/assetHandler';

export default defineComponent({
  name: 'FloorMap',
  props: {
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    hoveredUnit: {
      type: Unit,
    },
  },
  emits: ['unit-click', 'unit-hover'],
  data() {
    return {
      hoverUnit: undefined as undefined | Unit,
    };
  },
  computed: {
    useSvg(): boolean {
      return (
        this.curFloor.overlay.trim().length > 0 &&
        this.curFloor.elements.trim().length > 0
      );
    },
  },
  methods: {
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
    click(unit: Unit) {
      if (
        unit.available === Availability.unavailable ||
        this.units.find((fUnit: Unit) => fUnit.id === unit.id) === undefined
      ) {
        return;
      }

      this.$emit('unit-click', unit);
    },
    hover(unit: Unit | undefined) {
      if (unit && unit.available === Availability.unavailable) {
        return;
      }

      if (unit && this.hoverUnit && unit.id === this.hoverUnit.id) {
        return;
      }

      this.hoverUnit = unit;
      this.$emit('unit-hover', unit);
    },
    unitClasses(unit: Unit) {
      let classes: Record<string, boolean> = {};

      if (
        this.units.find((fUnit: Unit) => fUnit.id === unit.id) === undefined
      ) {
        classes = {
          'unit--unavailable': true,
        };
      } else {
        classes = {
          'unit--unavailable': unit.available === Availability.unavailable,
          'unit--available': unit.available === Availability.available,
          'unit--available-on': unit.available === Availability.availableOn,
          'unit--waitlist': unit.available === Availability.waitlist,
        };
      }

      return {
        ...classes,
        'unit--selected': this.hoveredUnit && unit.id === this.hoveredUnit.id,
      };
    },
    unitParts(unit: Unit) {
      const classes: Array<string> = [];

      if (
        this.units.find((fUnit: Unit) => fUnit.id === unit.id) === undefined
      ) {
        classes.push('floorplate-unit--unavailable')
      } else {
        if (unit.available === Availability.unavailable) {
          classes.push('floorplate-unit--unavailable')
        } else if (unit.available === Availability.available) {
          classes.push('floorplate-unit--available')
        } else if (unit.available === Availability.availableOn) {
          classes.push('floorplate-unit--available-on')
        } else if (unit.available === Availability.waitlist) {
          classes.push('floorplate-unit--waitlist')
        }
      }

      if (this.hoveredUnit && unit.id === this.hoveredUnit.id) {
        classes.push('floorplate-unit--selected');
      }

      return classes.join(' ');
    },
  },
});
</script>

<style lang="scss">
.floor-map {
  grid-area: floor-map;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  --floormap-text-color-default: var(--white, var(--white-default));

  &__image {
    max-width: 100%;
  }

  .svg-floor-layout {
    height: 100%;
    width: 100%;
  }

  a {
    & > *:not(text) {
      opacity: 0.2;
    }

    &:not(.unit--unavailable) {
      & > *:not(text) {
        opacity: 1;
      }
    }

    &.unit--unavailable {
      cursor: default;

      & > * {
        fill: gray;
      }
    }
  }

  a:hover:not(.unit--unavailable),
  a.unit--selected:not(.unit--unavailable) {
    *[id]:not(text) {
      fill: var(--accent, var(--accent-default));
    }
  }

  text {
    pointer-events: none;
    fill: var(--floormap-text-color, var(--floormap-text-color-default));
    font-family: var(--body-font, var(--body-font-default));
    font-size: var(--floormap-size, 1.375rem);
    font-weight: var(--floormap-weight, 300);
  }

  a:focus {
    outline: none;
  }

  a > * {
    outline: none;
  }
}
</style>
