<template>
  <Modal
    ref="modal"
    content-class="unit-modal__dialog"
    modal-name="unit"
    :active-modal="activeModal"
    part="unit-modal"
    :z-index="9001"
  >
    <VCard v-if="unit" class="unit-modal" part="unit-modal-card">
      <VCardTitle class="unit-modal__title--wrap">
        <h1 class="unit-modal__title">
          {{ getTitle }}
        </h1>
      </VCardTitle>

      <VCardSubtitle class="unit-modal__sub-title--wrap">
        <h2 class="unit-modal__sub-title">
          {{ getSubtitle }}
        </h2>
      </VCardSubtitle>

      <VCardText class="unit-modal__main-content">
        <div class="unit-modal__actions">
          <template v-for="(button, index) in modalButtons">
            <VBtn
              v-if="button.option === 'form' && displayFormButton"
              @click="$emit('open-form')"
              color="primary"
              part="unit-modal-button unit-modal-action-button unit-modal-action-button--form"
            >
              <VIcon left>mdi-check-circle-outline</VIcon>
              {{ interpolate(formButtonText, unit) }}
            </VBtn>

            <VBtn
              v-if="property && property.phone && button.option === 'telephone'"
              :href="`tel:${property.phone.replace(/-/g, '')}`"
              color="primary"
              @click="phoneEvent"
              part="unit-modal-button unit-modal-action-button unit-modal-action-button--telephone"
            >
              <VIcon left>mdi-phone-in-talk</VIcon>
              {{ property.phone }}
            </VBtn>

            <VBtn
              v-if="unit.applicationUrl && button.option === 'application'"
              :href="unit.applicationUrl"
              target="_blank"
              color="primary"
              part="unit-modal-button unit-modal-action-button unit-modal-action-button--application-url"
            >
              <VIcon left>mdi-email-outline</VIcon>
              {{ button.label }}
            </VBtn>

            <VBtn
              v-if="button.option === 'link'"
              :href="button.link"
              target="_blank"
              color="primary"
              @click="linkEvent(button)"
              part="unit-modal-button unit-modal-action-button unit-modal-action-button--link"
            >
              {{ button.label }}
            </VBtn>

            <VBtn
              v-if="button.option === 'overridable-link'"
              :href="getOverridableLink(button)"
              target="_blank"
              color="primary"
              part="unit-modal-button unit-modal-action-button unit-modal-action-button--overridable-link"
            >
              {{ button.label }}
            </VBtn>
          </template>
        </div>

        <div class="unit-modal__info">
          <VTabs v-model="tab" class="unit-modal__info-tabs">
            <template v-for="(modalTab, index) of modalTabs">
              <VTab
                v-if="shouldShowTab(modalTab)"
                :key="index"
                :value="modalTab.title"
              >
                {{ modalTab.title }}
              </VTab>
            </template>
          </VTabs>

          <VTabsItems v-model="tab">
            <template v-for="(modalTab, modalI) of modalTabs">
              <VTabItem
                v-if="shouldShowTab(modalTab)"
                :class="`unit-modal__tabs-${modalTab.title}`"
                :key="modalI"
              >
                <ul class="unit-modal__details">
                  <template v-for="(modalField, index) of modalTab.fields">
                    <li
                      v-if="
                        modalField.field === 'description' && unit.description
                      "
                      class="unit-modal__details-description"
                      part="unit-field-value--description"
                      v-html="decodeHtml(unit.description)"
                    ></li>

                    <li
                      v-else-if="
                        modalField.field !== 'description' &&
                        (interpolateCustom(modalField) ||
                          (interpolateCustom(modalField) &&
                            floorplanNavigator.settings.removeEmptyFields))
                      "
                      :key="index"
                      :class="`unit-modal__details-item unit-modal__details--${modalField.field}`"
                    >
                      <span
                        class="unit-modal__details-title"
                        :part="`unit-field-title--${modalField.field}`"
                      >
                        {{ modalField.label }}:
                      </span>

                      <span
                        class="unit-modal__details-value"
                        :part="`unit-field-value--${modalField.field} unit-modal-field-value--${modalField.field}`"
                      >
                        {{ interpolateCustom(modalField) }}
                      </span>
                    </li>
                  </template>
                </ul>
              </VTabItem>
            </template>
          </VTabsItems>
        </div>

        <div class="unit-modal__media">
          <VCarousel
            class="unit-modal__media-carousel"
            height="var(--modal-floorplan-height, var(--modal-floorplan-height-default))"
            :hide-delimiters="true"
            :show-arrows="floorplansNoPdf.length > 1"
            show-arrows-on-hover
            @change="activeFloorplanIndex = $event"
            v-model="floorplanIndex"
          >
            <VHover v-slot="{ hover }">
              <div
                class="unit-modal__floorplans"
                @click="$emit('open-floorplans', activeFloorplanIndex)"
              >
                <template v-for="(floorplan, index) in floorplansNoPdf">
                  <VCarouselItem
                    class="unit-modal__floorplan"
                    v-if="floorplan.image"
                    :key="floorplan.id"
                    :src="getPath(floorplan.image)"
                    contain
                  />

                  <div
                    v-if="activeFloorplanIndex === index"
                    class="unit-modal__floorplans--expand"
                  >
                    <VBtn
                      tile
                      v-show="hover"
                      @click="$emit('open-floorplans', activeFloorplanIndex)"
                      color="primary"
                    >
                      {{ $t('enlarge') }}
                    </VBtn>
                  </div>
                </template>
              </div>
            </VHover>
          </VCarousel>
        </div>

        <div class="unit-modal__view-media">
          <template v-for="(button, index) in modalMediaButtons">
            <VBtn
              v-if="button.option === 'photos' && unit.photos.length > 0"
              class="unit-modal__view-gallery"
              @click="$emit('open-gallery')"
              color="primary"
              part="unit-modal-button unit-modal-media-button unit-modal-media-button--gallery"
            >
              {{ button.label }}
            </VBtn>

            <VBtn
              v-if="
                (button.option === 'pdf' || button.option === 'floorplan') &&
                pdf
              "
              class="unit-modal__download-pdf"
              target="_blank"
              :href="pdf"
              color="primary"
              @click="pdfEvent"
              part="unit-modal-button unit-modal-media-button unit-modal-media-button--pdf"
            >
              {{ button.label }}
            </VBtn>

            <VBtn
              v-if="button.option === 'videos' && unit.video"
              class="unit-modal__view-videos"
              @click="$emit('open-videos')"
              color="primary"
              part="unit-modal-button unit-modal-media-button unit-modal-media-button--video"
            >
              {{ button.label }}
            </VBtn>

            <VBtn
              v-if="button.option === 'virtual-tours' && unit.virtualTour"
              class="unit-modal__view-videos"
              @click="$emit('open-virtual-tours')"
              color="primary"
              part="unit-modal-button unit-modal-media-button unit-modal-media-button--tours"
            >
              {{ button.label }}
            </VBtn>

            <VBtn
              v-if="button.option === 'link'"
              :href="button.link"
              target="_blank"
              color="primary"
              @click="linkEvent"
              part="unit-modal-button unit-modal-media-button unit-modal-media-button--link"
            >
              {{ button.label }}
            </VBtn>
          </template>
        </div>
      </VCardText>
    </VCard>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Unit } from '@/models/Unit';
import { decodeHtml } from '@/lib/helpers';
import { Property } from '@/models/Property';
import { AssetHandler } from '@/lib/assetHandler';
import {
  VCardSubtitle,
  VCard,
  VCardTitle,
  VCardText,
  VIcon,
  VBtn,
  VCarouselItem,
  VCarousel,
  VTabsItems,
  VTabItem,
  VTabs,
  VTab,
  VHover,
} from '@vuetify/lib/components';
import Modal from '@/components/Modal.vue';
import {
  AvailabilityForm,
  AvailabilityTypes,
  ModalButton,
  ModalField,
  ModalMediaButton,
  ModalTab,
} from '@/types/fields';
import { interpolate } from '../lib/interpolation';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { isModalCustomField } from '@/lib/typeGuards';
import { eventTracking } from '@/lib/customEvents';

export default defineComponent({
  name: 'UnitModal',
  components: {
    Modal,
    VCardSubtitle,
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
    VBtn,
    VCarouselItem,
    VCarousel,
    VTabsItems,
    VTabItem,
    VTabs,
    VTab,
    VHover,
  },
  props: {
    property: {
      type: Property,
      required: true,
    },
    floorplanNavigator: {
      type: FloorplanNavigator,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
    modalTabs: {
      type: Array as PropType<ModalTab[]>,
      required: true,
    },
    modalButtons: {
      type: Array as PropType<ModalButton[]>,
      required: true,
    },
    modalMediaButtons: {
      type: Array as PropType<ModalMediaButton[]>,
      required: true,
    },
    availabilityForms: {
      type: Object as PropType<Record<AvailabilityTypes, AvailabilityForm>>,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  emits: [
    'open-form',
    'open-gallery',
    'open-virtual-tours',
    'open-videos',
    'open-floorplans',
  ],
  data() {
    return {
      tab: 0,
      activeFloorplanIndex: 0,
      floorplanIndex: 0,
    };
  },
  computed: {
    getTitle() {
      if (this.floorplanNavigator.settings.modalUnitTitle) {
        return interpolate(
          this.floorplanNavigator.settings.modalUnitTitle,
          this.unit,
          this.unit.number
        );
      }

      return this.$t('unit_number', { number: this.unit.number });
    },
    getSubtitle() {
      if (this.floorplanNavigator.settings.modalUnitSubtitle) {
        return interpolate(
          this.floorplanNavigator.settings.modalUnitSubtitle,
          this.unit,
          this.unit.typeName
        );
      }

      return this.unit.typeName;
    },
    displayFormButton() {
      return (
        this.availabilityForm?.key ||
        this.availabilityForm?.link ||
        (this.unit.applicationUrl && this.unit.available !== 'notAvailable')
      );
    },
    floorplansNoPdf() {
      if (this.unit.floorplans) {
        return this.unit.floorplans.filter(
          floorplan => floorplan.image !== '' && floorplan.image !== null
        );
      }

      return [];
    },
    pdf() {
      if (this.unit.floorplans) {
        for (const floorplan of this.unit.floorplans) {
          if (floorplan.pdf) {
            return AssetHandler.getFloorplanPath(floorplan.pdf);
          }
        }
      }

      return '';
    },
    availabilityForm(): AvailabilityForm {
      return this.availabilityForms[this.unit.available];
    },
    formButtonText() {
      if (this.availabilityForm) {
        return this.availabilityForm.label;
      }

      return '';
    },
  },
  methods: {
    shouldShowTab(modalTab: ModalTab): boolean {
      return modalTab.fields.some(field => {
        if (isModalCustomField(field)) {
          const value = this.unit.customFields[field.customField];

          return value !== '' && value !== undefined && value !== null;
        }

        return (
          this.unit[field.field] !== '' &&
          this.unit[field.field] !== undefined &&
          this.unit[field.field] !== null
        );
      });
    },
    getOverridableLink(button: ModalButton) {
      if (
        button.overridableCustomField &&
        this.unit.customFields[button.overridableCustomField]
      ) {
        return this.unit.customFields[button.overridableCustomField];
      }

      return button.link;
    },
    interpolate,
    interpolateCustom(modalField: ModalField) {
      if (!isModalCustomField(modalField)) {
        const value =
          modalField.field === 'rate' && modalField.numberFormat === 'currency'
            ? Number.parseFloat(
                this.unit[modalField.field] as string
              ).toLocaleString('en-CA', {
                maximumFractionDigits: 2,
              })
            : this.unit[modalField.field];

        return interpolate(modalField.format, this.unit, value);
      }

      const value = this.unit.customFields[modalField.customField];

      return interpolate(modalField.format, this.unit, value);
    },
    phoneEvent() {
      eventTracking('call-initiated', this.unit!.id);
    },
    linkEvent(button: ModalButton) {
      eventTracking('custom', this.unit!.id, button.eventKey);
    },
    pdfEvent() {
      eventTracking('pdf-download', this.unit!.id);
    },
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
    showModal() {
      this.floorplanIndex = 0;
      this.activeFloorplanIndex = 0;
      this.tab = 0;
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal && this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    getPath(image: string) {
      return AssetHandler.getImagePath(`floorplans/${image}`);
    },
  },
});
</script>

<style lang="scss">
.unit-modal {
  padding: var(--modal-padding, var(--modal-padding-default));

  .v-card__text {
    display: grid;
    grid-template-areas: var(
      --modal-template-areas,
      var(--modal-template-areas-default)
    );
    grid-template-columns: var(
      --modal-template-columns,
      var(--modal-template-columns-default)
    );
    padding: 0 !important;
    gap: var(--modal-content-gap, var(--modal-content-gap-default));
  }

  &__info {
    grid-area: info;

    &-tabs {
      margin-bottom: 1.25rem;
    }

    button:not(.v-tab) {
      margin: 5px 15px 5px 0;
    }
  }

  &__media {
    grid-area: media;
    cursor: pointer;

    &-carousel,
    .v-window__container {
      max-height: var(
        --modal-floorplan-height,
        var(--modal-floorplan-height-default)
      );
    }
  }

  &__view-media {
    grid-area: view-media;
    display: flex;
    column-gap: 1.35rem;
    row-gap: 1rem;

    & > * {
      width: fit-content;
    }
  }

  .v-btn:focus::before {
    opacity: 0 !important;
  }

  &__title {
    line-height: 1;
    font-size: 3rem;

    &--wrap {
      padding: 0 !important;
      margin-bottom: 1rem;
    }
  }

  &__sub-title {
    line-height: 1;
    font-size: 2rem;

    &--wrap {
      padding: 0 !important;
      margin-bottom: 2rem;
      margin-top: 0 !important;
    }
  }

  &__actions {
    grid-area: actions;
    display: flex;
    gap: 1rem;

    button,
    a {
      width: fit-content !important;
    }
  }

  &__info,
  &__media,
  &__actions,
  &__view-media {
    margin: 0;
  }

  &__content {
    display: flex;
  }

  &__tabs-content {
    // height: 280px;
  }

  &__details:has(.unit-modal__details-description) {
    list-style: none;
  }

  &__details-item {
    display: flex;
  }

  &__details-title,
  &__details-value {
    padding: 7px 10px 7px 7px;
  }

  &__details-title {
    font-weight: bold;
    width: 150px;
  }

  &__floorplans {
    position: relative;

    &--expand {
      position: absolute;
      top: 40%;
      width: 100%;
      margin: auto;
      text-align: center;
      display: grid;
      justify-items: center;

      button {
        @media screen and (max-width: 768px) {
          display: block !important;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    --modal-template-areas-default: 'actions' 'info' 'view-media' 'media';
    --modal-content-gap-default: 2.2rem;
  }

  @media (max-width: 878px) {
    --modal-template-columns-default: 1fr;

    &__content {
      flex-direction: column;
    }

    &__info {
      width: 100%;
    }

    &__media {
      width: 100%;

      .v-responsive__content {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 480px) {
    --modal-padding-default: 60px 10px 20px 10px;
    --modal-content-gap-default: 1.75rem;

    &__actions,
    &__view-media {
      flex-direction: column;
    }
  }

  @media (max-width: 360px) {
    --modal-content-gap-default: 1.25rem;
  }

  &__dialog.v-dialog {
    overflow-x: clip;

    &::-webkit-scrollbar {
      width: 8px;

      &-track {
        background: transparent;
      }
    }

    @media (min-width: 1200px) {
      width: 1000px;
    }

    @media (max-width: 1199px) {
      width: calc(100vw - 60px);
      margin: 30px;
    }

    @media (max-width: 480px) {
      width: calc(100vw - 20px);
      margin: 10px;
    }
  }
}
</style>
