<template>
  <div class="loading-default">
    <h1 class="loading-default__heading">{{ text }}</h1>
    <VProgressCircular class="loading-default__circle" indeterminate />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VProgressCircular } from '@vuetify/lib/components';

export default defineComponent({
  components: {
    VProgressCircular,
  },
  props: {
    text: {
      type: String
    }
  },
});
</script>

<style lang="scss">
.loading-default {
  display: grid;
  place-content: center;
  place-items: center;
  min-height: 300px;
  text-align: center;
}
</style>
