<template>
  <Modal
    ref="modal"
    class="unit-media"
    :z-index="9005"
    modal-name="media"
    :active-modal="activeModal"
    part="unit-media-modal"
    @show="show"
    @hide="hide"
  >
    <div v-if="embedCode && showDialog" class="unit-media__embed-code" v-html="embedCode" />
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Unit } from '@/models/Unit';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    embedCode(): string | null {
      if (this.mediaType === 'videos' && this.unit.video) {
        return this.unit.video;
      } else if (this.unit.virtualTour) {
        return this.unit.virtualTour;
      }

      return '';
    },
  },
  methods: {
    showModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    show() {
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
    }
  },
});
</script>

<style lang="scss">
.unit-media {
  --close-btn-color-default: var(--black);

  &__embed-code {
    display: flex;
  }
}
</style>
