<template>
  <RenderlessFormBuilder
    v-slot="{
      captchaCompleted,
      captchaExpired,
      shouldRender,
      processForm,
      fieldUpdated,
      fieldset,
      fields,
      rules,
    }"
    :form="form"
    :property="property"
    :unit="unit"
    :lang="lang"
    @reset-recaptcha="resetRecaptcha"
    @submitted="submitted"
    @success="success"
    @error="error"
    @invoke-recaptcha="invokeRecaptcha"
  >
    <VForm
      v-if="form && Object.keys(form).length > 0"
      ref="form"
      class="form-builder"
      :class="`form-builder-default form-builder-default--${form.key}`"
      @submit.prevent="processForm"
    >
      <fieldset>
        <VAlert
          v-if="statusText"
          dense
          text
          :type="statusIsError ? 'error' : 'success'"
          dismissible
        >
          <div v-html="statusText"></div>
        </VAlert>

        <div>
          <legend v-if="fieldset && false">
            {{ fieldset.legend }}
          </legend>

          <template v-for="(field, index) of fields">
            <p v-if="field.type === 'paragraph'" part="form-paragraphs">
              {{ field.label }}
            </p>

            <VSelect
              v-if="shouldRender(field) && field.type === 'select'"
              @input="fieldUpdated($event, field)"
              :label="field.label"
              :items="getItems(field.values)"
              item-text="label"
              item-value="value"
              :rules="rules[field.name]"
            />

            <VTextField
              v-else-if="shouldRender(field) && field.type === 'number'"
              :key="index"
              :label="field.label"
              type="number"
              variant="outlined"
              :required="field.required"
              :rules="rules[field.name]"
              @input.native="fieldUpdated($event.target.value, field)"
            />

            <VTextField
              v-else-if="
                shouldRender(field) &&
                field.type === 'text' &&
                field.subtype !== 'date'
              "
              :key="index"
              :label="field.label"
              :type="field.subtype === 'date' ? 'date' : 'text'"
              variant="outlined"
              :required="field.required"
              :rules="rules[field.name]"
              @input.native="fieldUpdated($event.target.value, field)"
            />

            <VMenu
              v-else-if="
                shouldRender(field) &&
                field.type === 'text' &&
                field.subtype === 'date'
              "
              :ref="`menu-${field.name}`"
              v-model="datePickerMenu[field.name]"
              :close-on-content-click="false"
              :return-value.sync="datePickerDate[field.name]"
              transition="scale-transition"
              min-width="auto"
              attach
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="datePickerDate[field.name]"
                  :label="field.label"
                  readonly
                  :rules="rules[field.name]"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <VDatePicker
                v-model="datePickerDate[field.name]"
                no-title
                scrollable
                :allowed-dates="allowedDates"
                @input="fieldUpdated($event, field)"
              >
                <VSpacer />
                <VBtn
                  text
                  color="primary"
                  @click="datePickerMenu[field.name] = false"
                >
                  {{ $t('cancel') }}
                </VBtn>
                <VBtn
                  text
                  color="primary"
                  @click="
                    $refs[`menu-${field.name}`][0].save(
                      datePickerDate[field.name]
                    )
                  "
                >
                  {{ $t('ok') }}
                </VBtn>
              </VDatePicker>
            </VMenu>

            <VCheckbox
              v-else-if="shouldRender(field) && field.type === 'checkbox'"
              :key="field.name"
              :label="field.label"
              :rules="rules[field.name]"
              :required="field.required"
              :value="true"
              @change="fieldUpdated($event, field)"
            />

            <VTextarea
              v-else-if="shouldRender(field) && field.type === 'textarea'"
              :key="field.name"
              :label="field.label"
              type="date"
              variant="outlined"
              :rules="rules[field.name]"
              :required="field.required"
              @input.native="fieldUpdated($event.target.value, field)"
            />
          </template>
        </div>
      </fieldset>

      <div class="submit-section">
        <RecaptchaDefault
          ref="recaptcha"
          class="form-builder-default__recaptcha"
          @success="captchaCompleted"
          @expired="captchaExpired"
          @error="captchaExpired"
          @formError="error"
        />

        <VBtn
          class="form-builder__submit"
          type="submit"
          :loading="submitting"
          @click="startSubmission"
        >
          {{ $t('submit') }}
        </VBtn>
      </div>
    </VForm>
  </RenderlessFormBuilder>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue';
import RenderlessFormBuilder from './renderless/RenderlessFormBuilder.vue';
import RecaptchaDefault from './RecaptchaDefault.vue';
import { Form } from '@/models/Form';
import {
  VForm,
  VTextField,
  VTextarea,
  VBtn,
  VDatePicker,
  VMenu,
  VSpacer,
  VAlert,
  VCheckbox,
  VSelect
} from '@vuetify/lib/components';
import { Property } from '@/models/Property';
import { Unit } from '@/models/Unit';

export default defineComponent({
  name: 'FormBuilder',
  components: {
    RenderlessFormBuilder,
    RecaptchaDefault,
    VForm,
    VTextField,
    VTextarea,
    VBtn,
    VDatePicker,
    VMenu,
    VSpacer,
    VAlert,
    VCheckbox,
    VSelect,
  },
  props: {
    form: {
      type: Form,
    },
    property: {
      type: Property,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      datePickerMenu: {} as Record<string, string>,
      datePickerDate: {} as Record<string, string>,
      statusIsError: false,
      encodedStatusText: '',
      submitting: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.reset();
      },
    },
  },
  computed: {
    statusText(): string {
      const element = document.createElement('div');
      element.innerHTML = this.encodedStatusText;
      return element.innerText;
    }
  },
  methods: {
    getItems(values: { label: string, selected: boolean, value: string }[]) {
      return values.map(value => {
        return {
          label: value.label,
          value: value.value
        }
      });
    },
    startSubmission() {
      this.submitting = true;
    },
    invokeRecaptcha() {
      if (this.$refs.recaptcha) {
        (this.$refs.recaptcha as any).invokeChallenge();
      } else {
        this.statusText = 'An error has occurred. Please try again later'
        this.statusIsError = true
        this.submitting = false;
        console.warn('FPN: Recaptcha is missing can not invoke challenge.')
      }
    },
    resetRecaptcha() {
      if (this.$refs.recaptcha) {
        (this.$refs.recaptcha as any).resetRecaptcha();
      } else {
        this.statusText = 'An error has occurred. Please try again later'
        this.statusIsError = true
        this.submitting = false;
        console.warn('FPN: Recaptcha is missing can not reset it.')
      }
    },
    submitted() {
      this.submitting = false;
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    },
    success(message: string) {
      this.statusIsError = false;
      this.encodedStatusText = message;
      this.$emit('success');
    },
    error(message: string) {
      if (message === 'fix-inputs') {
        (this.$refs.form as any).validate();
      } else if (message !== 'captcha-issue') {
        this.statusIsError = true;
        this.encodedStatusText = message
          ? message
          : 'There was an error while submitting the form, please try again later';
      }
      this.submitting = false;
    },
    reset() {
      (this.$refs.form as any).reset();
      this.encodedStatusText = '';
      this.statusIsError = false;
      this.submitting = false;
    },
    allowedDates(value: string) {
      return new Date(value).getTime() > new Date().getTime();
    },
  },
});
</script>

<style lang="scss">
.form-builder {
  fieldset {
    border: none;
    padding: 0;

    margin: 20px 0 0;

    input[type='checkbox'] {
      visibility: hidden;
    }

    .v-input--checkbox .v-input__slot {
      background: none !important;
    }
  }

  &__submit {
    margin-top: 20px;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
