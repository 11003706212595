<template>
  <div class="unit-filters" part="unit-filters">
    <div class="unit-filters__mobile">
      <div @click="filtersButton('filters')" class="unit-filters__mobile--filters">
        <button>{{ $t('filters') }}</button>
      </div>

      <div @click="filtersButton('floors')" class="unit-filters__mobile--floors">
        <button>{{ $t('floors') }}</button>
      </div>
    </div>

    <div ref="filters" class="unit-filters__filters">
      <h3 class="unit-filters__filters--heading" part="mobile-heading">{{ $t('filters') }}</h3>

      <div class="unit-filters__filters--list">
        <template v-for="filter of filterFields">
          <VSelect
            :key="filter.field"
            v-model="filterValues[filter.field]"
            class="unit-filters__filter"
            :items="filterOptions[filter.field]"
            :label="filter.label"
            item-text="label"
            item-value="value"
            hide-details
            dense
            outlined
            flat
            solo
            :part="`filter filter-select filter-${filter.field}`"
          />
        </template>
      </div>

      <VBtn @click="resetFilters" class="unit-filters__reset" text>
        {{ $t('reset') }}
      </VBtn>
    </div>

    <ul ref="floors" class="unit-filters__floors">
      <li
        class="unit-filters__floor"
        v-for="(floor, index) in floors"
        :key="index"
        @click="floorClicked(floor)"
      >
        {{ $t('floor_#', {number: floor.number}) }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { Unit } from '@/models/Unit';
import type { UnitFields } from '@/types/unit-type';
import { VSelect, VBtn } from '@vuetify/lib/components';
import { FilterField } from '@/types/fields';
import { Floor } from '@/models/Floor';
import {interpolate} from "@/lib/interpolation";
import { Availability } from '@/types/unit-type';

export default defineComponent({
  name: 'UnitFilters',
  components: {
    VSelect,
    VBtn,
  },
  props: {
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    filterFields: {
      type: Array as PropType<FilterField[]>,
      required: true,
    },
    floors: {
      type: Array as PropType<Floor[]>,
      required: true,
    },
  },
  emits: ['filters-changed', 'update', 'set-floor'],
  data() {
    return {
      filterOptions: {} as Record<
        keyof Unit,
        { label: string; value: unknown }
      >,
      filterValues: {} as UnitFields,
    };
  },
  watch: {
    units() {
      this.updateFilters();
    },
    filterValues: {
      handler() {
        this.$emit('filters-changed', this.filterValues);
      },
      deep: true,
    },
  },
  created() {
    this.resetFilters();
    this.updateFilters();
  },
  methods: {
    resetFilters() {
      for (const filter of this.filterFields) {
        Vue.set(this.filterValues, filter.field, []);
      }

      this.$emit('filters-changed', this.filterValues);
    },
    updateFilters() {
      this.filterOptions = {} as Record<
        keyof Unit,
        { label: string; value: unknown }
      >;

      for (const filter of this.filterFields) {
        const opts = [
          {
            label: filter.label,
            value: null,
            disabled: true
          }
        ] as { value: any, label: string | null, disabled?: boolean }[];
        let values: unknown[] = [];

        if (filter.field === 'available') {
          if (this.units.find(unit => unit.available === Availability.available)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.available),
              value: Availability.available,
            });
          }

          if (this.units.find(unit => unit.available === Availability.unavailable)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.unavailable),
              value: Availability.unavailable,
            });
          }

          if (this.units.find(unit => unit.available === Availability.waitlist)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.waitlist),
              value: Availability.waitlist,
            });
          }

          if (this.units.find(unit => unit.available === Availability.availableOn)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.availableOn),
              value: Availability.availableOn,
            });
          }
        } else if (filter.increments === 0) {
          for (const unit of this.units) {
            const value = unit[filter.field];

            if (value !== null && !values.includes(value)) {
              values.push(value)
            }
          }

          values = values.sort()

          for (const value of values) {
            opts.push({
              value,
              label: interpolate(filter.format, filter, value),
            });
          }
        } else {
          let min = 9001;
          let max = 0;

          for (const unit of this.units) {
            const field = unit[filter.field];
            if (field && !Number.isNaN(Number.parseInt(field as any, 10))) {
              const parsedField = Number.parseInt(field as any, 10);

              if (parsedField < min) {
                min = typeof field === 'string' ? Number.parseInt(field) : field as number;
              } else if (parsedField > max) {
                max = typeof field === 'string' ? Number.parseInt(field) : field as number;
              }
            }
          }

          const increment = filter.increments;

          min = Math.floor(min / increment) * increment;
          max = Math.ceil(max / increment) * increment;

          if (filter.filter === 'min') {
            max -= increment;
          } else if (filter.filter === 'max') {
            min += increment;
          }

          // If the min and max are the same, we don't want to show any options
          // initialize the min value to roundedLowerBound and max value to upperBound to ensure that the range is inclusive
          for (let i = min; i <= max; i += increment) {
            opts.push({
              value: i,
              label: interpolate(filter.format, filter, i),
            });
          }
        }

        Vue.set(this.filterOptions, filter.field, opts);
      }
    },
    filtersButton(type: string) {
      const elementContainer = this.$refs[type] as Element;

      if (elementContainer) {
        elementContainer.classList.toggle('active');
      }
    },
    floorClicked(floor: Floor) {
      this.$emit('set-floor', floor);

      this.filtersButton('floors');
    },
  },
});
</script>

<style lang="scss">
.unit-filters {
  grid-area: unit-filters;
  background-color: var(--unit-filters-bg, var(--unit-filters-bg-default));
  color: var(--unit-filters-font-color, var(--unit-filters-font-color-default));
  padding: var(--unit-filters-padding, var(--unit-filters-padding-default));

  &__filters {
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;

    &--list {
      flex: 1;

      display: grid;
      align-items: center;
      grid-template-columns: var(--unit-filters-col-grid, var(--unit-filters-col-grid-default));
      row-gap: 10px;
      column-gap: 10px;
    }

    &--heading {
      background-color: var(--action, var(--action-default));
      color: var(--white, var(--white-default));
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      padding: 1rem;
      display: none;
    }
  }

  &__mobile {
    display: none;
    width: 100%;
    gap: 2px;

    &--filters,
    &--floors {
      background-color: var(--black, var(--black-default));
      color: var(--white, var(--white-default));
      padding: 1rem 0.8rem;
      text-align: center;
      flex: 1;
    }

    button {
      text-transform: uppercase;
    }
  }

  &__reset {
    margin: auto;
    color: var(--white, var(--white-default)) !important;
    padding: 0 !important;
  }

  &__floors {
    display: none;
    padding: 0 !important;
    list-style: none;

    &.active {
      display: block;
    }
  }

  &__floor {
    font-size: 24px;
    font-weight: bold;
    padding: 20px 10px;
    border-bottom: 1px solid #757575;
    cursor: pointer;
  }

  @media (min-width: 1200px) and (max-width: 1346px) {
    --unit-filters-col-grid-default: 1fr 1fr;
  }

  @media (max-width: 767px) {
    --unit-filters-bg-default: transparent;
    --unit-filters-font-color-default: var(--black, var(--black-default));
    --unit-filters-padding-default: 0;
    --unit-filters-col-grid-default: 1fr;

    &__mobile {
      display: flex;
    }

    &__filters {
      display: none;
      background: var(--black, var(--black-default));

      &.active {
        display: flex;
      }

      &--list {
        flex: 100%;
        padding: 0 10px;
      }

      &--heading {
        display: block;
      }
    }

    &__reset {
      margin-bottom: 10px;
    }
  }

  // Overrides

  // .v-input__slot {
  //   margin: 0;
  //   background-color: #ffffff;
  //   min-height: 40px !important;
  // }

  // .v-select {

  //   &__selections {
  //     padding: 0 !important;

  //     input {
  //       padding: 0;
  //     }
  //   }
  // }

  // .v-label {
  //   &:not(.v-label--active) {
  //     top: 50%;
  //     transform: translateY(-50%);
  //   }
  // }

  // .v-input {

  //   &__append-inner {
  //     position: absolute;
  //     top: 50%;
  //     right: 0;
  //     margin: 0 !important;
  //     transform: translateY(-50%);
  //   }
  // }
}
</style>
