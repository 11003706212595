<template>
  <div class="no-availability">
    <h1 class="no-availability__heading">{{ text }}</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String
    }
  },
});
</script>

<style lang="scss">
.no-availability {
  display: grid;
  place-content: center;
  place-items: center;
  min-height: 300px;
  text-align: center;
}
</style>
