<template>
  <div />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { httpClient } from '@/lib/httpClient';

export default defineComponent({
  name: 'RecaptchaDefault',
  props: {
    size: {
      type: String,
      default: 'invisible',
      validator: (value: string) =>
        ['normal', 'compact', 'invisible'].indexOf(value) > -1,
    },
  },
  emits: ['success', 'expired', 'error', 'formError'],
  data() {
    return {
      siteKey: '',
      unsubscribe: undefined as any,
      unsubscribeKey: undefined as any,
      unsubscribeType: undefined as any,
      recaptchaId: undefined as any,
      recaptchaReady: false,
      observer: null as MutationObserver | null,
      recaptchaChallenge: null as HTMLElement | null,
    };
  },
  onUnmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  created() {
    this.siteKey = httpClient.recaptchaPublicKey;
  },
  async mounted() {
    if (window.grecaptcha && typeof window.grecaptcha.render === 'function') {
      await this.applyRecaptcha();
    } else {
      this.loadRecaptcha();
    }
  },
  beforeUnmount() {
    if (this.unsubscribe !== undefined) {
      this.unsubscribe();
    }

    if (this.unsubscribeType !== undefined) {
      this.unsubscribeType();
    }

    if (this.unsubscribeKey !== undefined) {
      this.unsubscribeKey();
    }
  },
  methods: {
    loadRecaptcha() {
      window.fpnRecaptchaLoaded = this.recaptchaLoaded;
      const scriptDiv = document.createElement('div');
      scriptDiv.setAttribute('id', 'grecaptcha-script');
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://www.google.com/recaptcha/api.js?render=explicit&onload=fpnRecaptchaLoaded'
      );
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      scriptDiv.append(script);
      document.body.append(scriptDiv);
    },
    recaptchaLoaded() {
      this.recaptchaReady = true;
      this.applyRecaptcha();
    },
    async applyRecaptcha() {
      if (this.siteKey !== '') {
        // Make sure it hasn't already been loaded in
        if (this.recaptchaId === undefined) {
          const recaptcha = document.createElement('div');
          recaptcha.id = 'fpn-recaptcha';
          recaptcha.setAttribute('ref', 'recaptcha');
          document.body.appendChild(recaptcha);
          this.recaptchaId = window.grecaptcha.render(recaptcha as Element, {
            sitekey: this.siteKey,
            size: this.size,
            isolated: true,
            callback: this.captchaCompleted,
            expiredCallback: this.captchaExpired,
            errorCallback: this.captchaError,
          });
        }
      }
    },
    captchaCompleted(token: string) {
      this.$emit('success', token);
    },
    captchaExpired() {
      this.$emit('expired');
    },
    captchaError() {
      this.$emit('error');
    },
    async invokeChallenge() {
      this.recaptchaChallenge = document.querySelector('#fpn-recaptcha')
        ?.nextElementSibling as HTMLElement;
      if (!this.recaptchaChallenge) {
        setTimeout(() => {
          this.invokeChallenge();
        }, 500);
        return;
      }
      this.observer = new MutationObserver((mutationList, observer) => {
        if (
          this.recaptchaChallenge &&
          this.recaptchaChallenge.style &&
          this.recaptchaChallenge.style.visibility === 'visible'
        ) {
          let top = '50%';
          const recaptchaPictureElement: HTMLElement = this.recaptchaChallenge
            ?.childNodes[1] as HTMLElement;
          if (recaptchaPictureElement) {
            const h = window.innerHeight / 2;
            const recaptchaPictureElementHeight =
              Number(recaptchaPictureElement.style.height.replace('px', '')) /
              2;
            const newHeight = h - recaptchaPictureElementHeight;
            top = `${newHeight}px`;
          }

          this.recaptchaChallenge.style.top = top;
          this.recaptchaChallenge.style.position = `fixed`;
          this.recaptchaChallenge.style.zIndex = `999999`;
        } else if (
          this.recaptchaChallenge &&
          this.recaptchaChallenge.style &&
          this.recaptchaChallenge.style.visibility === 'hidden' &&
          mutationList.length != 4
        ) {
          this.resetRecaptcha();
          this.$emit('formError', 'captcha-issue');
        }
      });

      this.observer.observe(this.recaptchaChallenge, {
        subtree: true,
        attributes: true,
      });

      await window.grecaptcha.execute(this.recaptchaId);
    },
    resetRecaptcha() {
      window.grecaptcha.reset(this.recaptchaId);
    },
  },
});
</script>

<style lang="scss">
.recaptcha-default {
  .grecaptcha-badge {
    position: relative !important;
    bottom: unset !important;
    right: unset !important;
  }
}
</style>
