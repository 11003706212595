<template>
  <Modal
    ref="modal"
    class="unit-gallery"
    :z-index="9005"
    modal-name="gallery"
    :active-modal="activeModal"
    part="unit-gallery-modal"
  >
    <VCarousel
      v-model="index"
      class="unit-gallery__modal"
      :hide-delimiter-background="true"
      :show-arrows="unit.photos.length > 1"
      show-arrows-on-hover
      height="90vh"
    >
      <VCarouselItem
        v-for="photo of unit.photos"
        :key="photo.image"
        class="unit-gallery__item"
        :src="getPath(photo.image)"
        contain
      />
    </VCarousel>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Unit } from '@/models/Unit';
import { AssetHandler } from '@/lib/assetHandler';
import { VCarousel, VCarouselItem } from '@vuetify/lib/components';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
    VCarousel,
    VCarouselItem,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      index: 0,
    };
  },
  methods: {
    showModal() {
      this.index = 0;

      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    getPath(image: string) {
      return AssetHandler.getImagePath(`gallery/1152/${image}`);
    },
  },
});
</script>

<style lang="scss">
.unit-gallery {
  --close-btn-color-default: white;

  .v-dialog {
    box-shadow: none;
    max-width: 80vw;
  }

  .v-icon.mdi-close {
    color: white;
  }

  &__item {
    min-width: 50vw;
  }
}
</style>
