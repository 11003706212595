<template>
  <div class="property-image" part="property-image">
    <img
      ref="buildingImage"
      :src="buildingImage"
      :alt="$t('building_photo')"
      part="building-image"
      @load="setRatio"
    />

    <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewbox">
      <a
        v-for="(floor, i) in floors"
        :key="i"
        class="property-image__floor"
        :class="{
          [`property-image__floor--${i}`]: true,
          'property-image__floor--selected': curFloor.number === floor.number,
        }"
        @click="$emit('floor-click', floor)"
        @keyup="$emit('floor-click', floor)"
        v-html="decodeHtml(floor.overlay)"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { decodeHtml } from '@/lib/helpers';

export default defineComponent({
  name: 'PropertyImage',
  props: {
    buildingImage: {
      type: String,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    floors: {
      type: Array as PropType<Array<Floor>>,
      required: true,
    },
  },
  emits: ['floor-click', 'set-ratio'],
  data() {
    return {
      viewbox: '0 0 1000 1000',
    };
  },
  methods: {
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
    setRatio() {
      this.$emit('set-ratio', (this.$refs.buildingImage as any).naturalWidth / (this.$refs.buildingImage as any).naturalHeight);

      this.viewbox = `0 0 ${(this.$refs.buildingImage as any).naturalWidth} ${
        (this.$refs.buildingImage as any).naturalHeight
      }`;
    },
  },
});
</script>

<style lang="scss">
.property-image {
  grid-area: property-image;
  position: relative;

  svg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  img {
    position: static;
  }

  a:hover,
  a.property-image__floor--selected {
    [id] {
      opacity: var(--overlay-opacity, 0.5);
      cursor: pointer;
    }
  }

  a:hover {
    fill: var(
      --property-image-hover,
      var(--accent, var(--accent-default))
    );
  }

  a.property-image__floor--selected {
    fill: var(
      --property-image-selected,
      var(--accent, var(--accent-default))
    );
  }

  [id] {
    opacity: 0;
  }
}
</style>
